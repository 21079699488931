import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import  swal from 'sweetalert';

@Component({
  selector: 'app-create-po-modal',
  templateUrl: './create-po-modal.component.html'
})
export class CreatePoModalComponent implements OnInit {
  
  form:any={};
  constructor(public db:DatabaseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _location: Location) { 
      
      this.form.orderItem=data;
      this.form.basicData={
        net_amt:null,
        total_qty:null,
        total_amt:null,
        tax_amt:null,
        discount_amt:null,
        orderItem:null,
        vendor_id:null,
        po_no:null,
        remark : '',
        po_date:moment(new Date()).format("YYYY-MM-DD"),
        delivery_date:moment(new Date()).format("YYYY-MM-DD"),
      };
      this.form.product_detail={
        stock_qty:null,
        order_qty:null,
        rate:null,
        discount:null,
        discount_amt:null,
        tax:null,
        tax_amt:null,
        total_amt:null
      };

      console.log('data ->', this.data);
      this.getPONo();

    }
    
    
    getPONo(){
      let reqData = {
        
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'po/get_po_no').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.form.basicData.po_no = resp['result'].data.po_no;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }

    formSubmitted:any;
    // data:any = this.db.redeemRequestData
    ngOnInit() {
      
    }
    
    todayDate:any
    formsInit(data:any) {
      console.log(data);
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      // this.form = this.db.formBuilder.group({
      //   status: [data.status ? data.status : '',[Validators.required]],
      //   travel_id: [data.id ? data.id : null ,[]],
      //   remark: [data.remark ? data.remark : null ,[Validators.required]],
      // })
      console.log(this.form.value);
      
    }
    backClicked() {
      this._location.back();
    }
    
 
    addConfirmation(){
      swal({
        title: "Are you sure?",
        text: "You want place this purchase!" ,
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((submit) => {        
        if (submit) {
          this.onSubmit();
        }
      });
    }
    orderEditId:any=0;
    onSubmit(){
      console.log(this.form);
      
      this.form.orderItem.map(r=>{
        this.form.basicData.total_qty += r.order_qty
      });
      
      let reqData = this.form.basicData
      reqData.orderItem = this.form.orderItem
      reqData.total_item = this.form.orderItem.length
  
      reqData.vendor_id = 2;
      reqData.po_id = this.orderEditId;
      
      console.log(reqData);
      if(!this.form.basicData.remark){
        return;
      }
      // sample json payload data
      const addData = {"net_amt":"9260.00","total_qty":"20.00","total_amt":"6009.74","tax_amt":"916.74","discount_amt":"4167.00","orderItem":[{"stock_qty":116,"order_qty":20,"rate":463,"discount":45,"discount_amt":"4167.00","tax":18,"tax_amt":"916.74","total_amt":"6009.74","product_id":11407,"vendor_product_name":"11IR 11W OP1215J","product_group":"THERADING INSERTS","product_code":"KOK9359","dispatch_qty":0,"pending_qty":20,"net_amt":"9260.00"},{"stock_qty":37,"order_qty":10,"rate":463,"discount":45,"discount_amt":"2083.50","tax":18,"tax_amt":"458.37","total_amt":"3004.87","product_id":9780,"vendor_product_name":"16ER 100ISO U45","product_group":"THERADING INSERTS","product_code":"KOK7696","dispatch_qty":0,"pending_qty":10,"net_amt":"4630.00"}],"vendor_id":2,"po_no":"PO-ORDER-78","po_date":"2024-12-29","delivery_date":"2024-12-29","remark":"Test Data","total_item":2};

      this.db.presentLoader();
      this.db.postReq(reqData,this.orderEditId ? "po/update" :'po/add').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.db.successAlert(resp['status'],resp['message']);
          this.db.matdialog.closeAll();
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
      
    }
    
  }
  